import React, { useState } from "react";
import { AuthInputType, SearchBarInputType } from "../../Utils/Types";
import { StyledNotVisible, StyledVisible } from "./Styles";
import { IoMdSearch } from "react-icons/io";

const TextInputField: React.FC<AuthInputType> = ({
  label,
  name,
  type,
  placeholder,
  onChange,
  error,
  value,
}) => {
  return (
    <div
      className={
        !error.valid
          ? "relative flex-row item-center justify-between w-full p-3 border-[0.1rem] rounded-md border-[rgba(217, 217, 217, 1)]"
          : "animate-shake rounded-md border-error border-[0.1rem] p-3"
      }
    >
      <div
        className={` ${
          !error.valid
            ? "w-auto px-[0.5rem] absolute  text-authGrey  rounded-md"
            : "w-auto px-[0.5rem] absolute bg-white z-10 text-error"
        } top-0 translate-y-[-50%] bg-white z-10 text-[0.7rem] `}
      >
        {label}
      </div>
      <input
        title="Field"
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="w-[95%] px-3"
      />
    </div>
  );
};

const PasswordInputField: React.FC<AuthInputType> = ({
  label,
  name,
  type,
  onChange,
  error,
  value,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const handleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <div
      className={
        !error.valid
          ? "relative flex-row item-center justify-between w-full p-3 border-[0.1rem] rounded-md border-[rgba(217, 217, 217, 1)]"
          : "animate-shake rounded-md border-error border-[0.1rem] p-3"
      }
    >
      <div
        className={` ${
          !error.valid
            ? "w-auto px-[0.5rem] absolute  text-authGrey  rounded-md"
            : "w-auto px-[0.5rem] absolute bg-white z-10 text-error"
        } top-0 translate-y-[-50%] bg-white z-10 text-[0.7rem] `}
      >
        {label}
      </div>
      <input
        title="Password"
        type={visible ? "text" : type}
        value={value}
        onChange={onChange}
        name={name}
        className="w-[95%] pl-3"
        placeholder="******"
      />
      {!visible ? (
        <StyledNotVisible onClick={handleVisibility} />
      ) : (
        <StyledVisible onClick={handleVisibility} />
      )}
    </div>
  );
};

export const AuthInput: React.FC<AuthInputType> = ({
  label,
  name,
  type,
  placeholder,
  onChange,
  error,
  value,
}) => {
  return (
    <React.Fragment>
      {name === "gender" ? (
        <div
          className={
            !error.valid
              ? "relative flex-row item-center justify-between w-full p-3 border-[0.1rem] rounded-md border-[rgba(217, 217, 217, 1)]"
              : "animate-shake rounded-md border-error border-[0.1rem] p-3"
          }
        >
          <div
            className={` ${
              !error.valid
                ? "w-auto px-[0.5rem] absolute  text-authGrey  rounded-md"
                : "w-auto px-[0.5rem] absolute bg-white z-10 text-error"
            } top-0 translate-y-[-50%] bg-white z-10 text-[0.7rem] `}
          >
            {label}
          </div>
          <select
            name={name}
            value={value}
            onChange={onChange}
            className="w-full bg-transparent border-none text-authGrey outline-none text-base"
          >
            <option value="">Select Gender</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </select>
        </div>
      ) : type === "password" ? (
        <PasswordInputField
          label={label}
          type={type}
          name={name}
          onChange={onChange}
          error={error}
          value={value}
          placeholder={placeholder}
        />
      ) : (
        <TextInputField
          label={label}
          type={type}
          name={name}
          onChange={onChange}
          error={error}
          value={value}
          placeholder={placeholder}
        />
      )}
    </React.Fragment>
  );
};

export const SearchBarInput: React.FC<SearchBarInputType> = ({
  value,
  onChange,
  handleSearch,
}) => {
  return (
    <div className=" w-full max-w-[280px] flex flex-row items-center justify-between bg-dashboardContrast rounded-md py-2 px-3">
      <input
        title="Field"
        type={"text"}
        value={value}
        placeholder="Search"
        onChange={onChange}
        className="w-[95%] px-3 bg-transparent"
      />
      <IoMdSearch
        color="rgba(1, 12, 166, 1)"
        size={25}
        cursor={"pointer"}
        onClick={handleSearch}
      />
    </div>
  );
};
