import React from "react";
import {
  Routes as MyRoutes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { ROLES } from "../Utils/Utils";
import Register from "../Components/Auth/Register";
import Login from "../Components/Auth/Login";
import Privileges from "./Privileges";
import UserDashboard from "../Components/Dashboard";
import VerifyEmail from "../Components/Auth/VerifyEmail";
import VerifyOtp from "../Components/Auth/VerifyOtp";
import Reset from "../Components/Auth/Reset";
import CategoriesIndex from "../Components/Categories";
import BlogIndex from "../Components/Blogs";
import UserIndex from "../Components/Users";
import EditProfile from "../Components/Profile/EditProfile";
import PrivateRoute from "./PrivateRoute";

const Routes: React.FC = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <MyRoutes location={location} key={location.pathname}>
        {/* ONBOARDING */}
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/sign-up" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        <Route path="/reset" element={<Reset />} />

        {/* APP */}
        <Route element={<PrivateRoute />}>
          {/* ADMIN ROUTES */}
          <Route element={<Privileges role="admin" />}>
            <Route path="/admin">
              <Route index element={<UserDashboard role={ROLES.ADMIN} />} />
              <Route path="users">
                <Route index element={<UserIndex />} />
                <Route path=":id" element={<BlogIndex isControlled={true} />} />
              </Route>
              <Route path="categories" element={<CategoriesIndex />} />
              <Route path="profile" element={<EditProfile />} />
              <Route
                path="posts"
                element={<BlogIndex isControlled={false} />}
              />
            </Route>
          </Route>

          {/* USER ROUTES */}
          <Route element={<Privileges role={ROLES.USER} />}>
            <Route path="/user">
              <Route index element={<UserDashboard role={ROLES.USER} />} />
              <Route path="categories" element={<CategoriesIndex />} />
              <Route path="profile" element={<EditProfile />} />
              <Route
                path="posts"
                element={<BlogIndex isControlled={false} />}
              />
            </Route>
          </Route>
        </Route>
      </MyRoutes>
    </AnimatePresence>
  );
};

export default Routes;
