import React from "react";
import Routes from "./Routing/Routes";
import "./App.css";
import Provider from "./Contexts/Provider";
import ScreenSizeWrapper from "./Components/ScreenSizeWrapper";
import { Toaster } from "sonner";
import { FaRegCircleCheck } from "react-icons/fa6";

const App: React.FC = () => {
  return (
    <ScreenSizeWrapper>
      <Provider>
        <Toaster
          position="top-left"
          toastOptions={{
            duration: 1500,
            unstyled: true,
            classNames: {
              toast:
                "bg-[#1E1E1EC9] p-3 flex items-center gap-3 ml-[224px] w-full",
              title: "text-[#FEFEFE] text-[18px] whitespace-no-wrap ml-3",
              description: "text-[#FEFEFE] text-[18px] whitespace-no-wrap ml-3",
              // actionButton: "bg-zinc-400",
              // cancelButton: "bg-orange-400",
              // closeButton: "bg-lime-400",
            },
          }}
          icons={{
            success: <FaRegCircleCheck color="#646464" size={30} />,
            //   info: <InfoIcon />,
            //   warning: <WarningIcon />,
            //   error: <ErrorIcon />,
            //   loading: <LoadingIcon />,
          }}
        />
        <div className="App">
          <Routes />
        </div>
      </Provider>
    </ScreenSizeWrapper>
  );
};

export default App;
