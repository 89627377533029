import back from '../Assets/others/back.svg'

const Back:React.FC<{action: ()=>void}> = ({action}) => {
    return(
        <img 
            src={back} 
            alt="Back" 
            onClick={action}
            className="cursor-pointer"
        />
    )
}

export default Back;