import React, { useEffect, useState } from "react";
import { AppCard } from "../Card";
import Button from "../../UI/Button";
import { MdOutlineCloudUpload } from "react-icons/md";
import eye from "../../Assets/others/eye.svg";
import api from "../../API/axios";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/Store";
import { resetSuccess, updateUser } from "../../Store/Slices/UsersSlice";
import Spinner from "../../UI/Spinner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Validation schema using yup
const schema = yup.object().shape({
  username: yup.string().required("Username is required."),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required."),
  gender: yup.string().required("Gender is required."),
  password: yup
    .string()
    .nullable() // Makes the password field optional
    .notRequired() // Password is no longer required
    .min(6, "Password must be at least 6 characters long")
    .transform((value) => (value === "" ? null : value)), // Ignores empty strings
  role: yup.string().required("Role is required."),
  coverPhoto: yup.mixed().notRequired(),
});

const EditProfile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, success } = useSelector((state: RootState) => state.users);
  const { user } = useSelector((state: RootState) => state.auth);
  const [viewPassword, setViewPassword] = useState(false);
  const [photoPreview, setPhotoPreview] = useState<string | null>(null);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);

  const togglePassword = () => setViewPassword((prev) => !prev);

  const handleUploadClick = () => {
    const inputElement = document.getElementById(
      "coverPhoto"
    ) as HTMLInputElement | null;
    if (inputElement) inputElement.click();
  };

  const uploadCoverPhoto = async (file: File) => {
    setUploadLoading(true);
    const formData = new FormData();
    formData.append("images", file);

    try {
      const response = await api.post("/user/upload", formData);
      return response.data.data[0].url;
    } catch (error) {
      console.error("Error uploading cover photo:", error);
      return null;
    } finally {
      setUploadLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setPhotoPreview(reader.result as string);
      reader.readAsDataURL(file);

      const uploadedUrl = await uploadCoverPhoto(file);
      setValue("coverPhoto", uploadedUrl || "");
    }
  };

  const onSubmit = async (data: any) => {
    const coverPhoto = data.coverPhoto || user?.avatar;

    const updatedUserData: any = {
      fullName: data.username,
      email: data.email,
      avatar: coverPhoto,
    };

    if (data.password) {
      updatedUserData.password = data.password;
    }

    dispatch(updateUser(updatedUserData));
  };
  useEffect(() => {
    if (user) {
      setValue("username", user.fullName);
      setValue("email", user.email);
      setValue("gender", user.gender);
      setValue("role", user.role);
      setPhotoPreview(user?.avatar ?? null);
    }
  }, [user, setValue]);

  useEffect(() => {
    if (success) {
      dispatch(resetSuccess());
    }
  }, [success, dispatch]);

  return (
    <AppCard>
      {(loading || uploadLoading) && <Spinner />}
      <div className="p-6 mt-16 w-full max-w-[1200px] mx-auto">
        <div className="mt-[50px]">
          <div className="text-center font-semibold text-[28px] leading-[54.82px] mb-[22px]">
            Edit Profile
          </div>
          <form
            className="flex flex-col gap-[24px] w-full max-w-[700px] mx-auto"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* Username Field */}
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="username"
                className="text-xl leading-[30px] text-[#344054]"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                {...register("username")}
                className={`border rounded-[8px] text-xl ${
                  errors.username ? "border-red-500" : "border-[#D0D5DD]"
                }`}
                style={{ padding: "11.85px 15.8px" }}
              />
              {errors.username && (
                <p className="text-red-500 text-sm">
                  {errors.username.message}
                </p>
              )}
            </div>

            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="email"
                className="text-xl leading-[30px] text-[#344054]"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                {...register("email")}
                className={`border rounded-[8px] text-xl ${
                  errors.email ? "border-red-500" : "border-[#D0D5DD]"
                }`}
                style={{ padding: "11.85px 15.8px" }}
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email.message}</p>
              )}
            </div>

            {/* Password Field */}
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="password"
                className="text-xl leading-[30px] text-[#344054]"
              >
                Password
              </label>
              <div className="border border-[#D0D5DD] bg-white rounded-[8px] p-3 flex justify-between">
                <input
                  type={viewPassword ? "text" : "password"}
                  id="password"
                  {...register("password")}
                  className="bg-transparent rounded-[8px] text-xl w-9/12"
                />
                <img
                  src={eye}
                  alt="Toggle Password Visibility"
                  className="cursor-pointer"
                  onClick={togglePassword}
                />
              </div>
              {errors.password && (
                <p className="text-red-500 text-sm">
                  {errors.password.message}
                </p>
              )}
            </div>

            {/* Profile Photo Field */}
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="coverPhoto"
                className="text-xl leading-[30px] text-[#344054]"
              >
                Profile Photo
              </label>
              <div
                className={`file-upload-area cursor-pointer border-2 custom-dash border-dashed border-[#1849D6] flex flex-col gap-3 rounded-[12px] text-center items-center ${
                  !photoPreview ? " py-[45px] w-full" : "p-[12px] w-fit"
                }`}
                onClick={handleUploadClick}
              >
                {photoPreview ? (
                  <img
                    src={photoPreview}
                    alt="uploaded cover"
                    className="w-[344px] h-[239px] object-cover rounded-[8px]"
                  />
                ) : (
                  <>
                    <MdOutlineCloudUpload className="w-[48px] h-[48px]" />
                    <p className="text-xl text-[#1849D6]">
                      Click to upload cover photo
                    </p>
                  </>
                )}
              </div>
              <input
                type="file"
                id="coverPhoto"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {errors.coverPhoto && (
                <p className="text-red-500 text-sm">
                  {errors.coverPhoto.message}
                </p>
              )}
            </div>

            {/* Submit Button */}
            <div className="mt-[30px]">
              <Button
                style={{
                  width: "fit-content",
                }}
                text="Save Changes"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </AppCard>
  );
};

export default EditProfile;
