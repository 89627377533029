import React, {useState} from 'react'

const PeriodOfTheDay:React.FC = () => {
    const [period] = useState<number>(new Date(Date.now()).getHours())
    let currentPeriod:string;

    if(period >= 0 && period < 12){
        currentPeriod = 'Morning.'
    }
    else if(period >= 12 && period < 16){
        currentPeriod = 'Afternoon.'
    }
    else{
        currentPeriod = 'Evening.'
    }
    
    setInterval(()=>{

    },60000)

    return (
        <p className="text-authGrey mt-2 font-medium">
            Good {currentPeriod}  
        </p>
    )
}

export default PeriodOfTheDay