import React, { useState, useEffect, ReactNode } from "react";

interface ScreenSizeWrapperProps {
  children: ReactNode;
}
const ScreenSizeWrapper = ({ children }: ScreenSizeWrapperProps) => {
  const [isScreenLargeEnough, setIsScreenLargeEnough] = useState(
    window.innerWidth >= 1440
  );

  useEffect(() => {
    const handleResize = () => {
      setIsScreenLargeEnough(window.innerWidth >= 1440);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!isScreenLargeEnough) {
    return (
      <div className="flex justify-center items-center h-screen bg-[#f0f0f0] text-center p-5 text-[24px] text-[#333]">
        <h1>Please open on a larger screen</h1>
      </div>
    );
  }

  return <>{children}</>;
};

export default ScreenSizeWrapper;
