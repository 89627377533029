import React, { useState } from "react";
import { AppCard } from "../Card";
import { SecondaryButton } from "../../UI/Button";
import Back from "../../UI/Back";
import { AnimatePresence, motion } from "framer-motion";
import { Blog, deleteBlog } from "../../Store/Slices/BlogsSlice";
import Spinner from "../../UI/Spinner";
import { User } from "../../Store/Slices/UsersSlice";
import { Category } from "../../Store/Slices/CategorySlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";

interface ManageAuthorsPostsProps {
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  users: User[];
  categories: Category[];
  loading: boolean;
  blogs: Blog[];
  selectedUser?: User;
}

const ManageAuthorsPosts = ({
  setCurrentIndex,
  users,
  categories,
  loading,
  blogs,
  selectedUser,
}: ManageAuthorsPostsProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const authorName = selectedUser?.fullName
    ? selectedUser.fullName.split(" ")[0]
    : "Unknown Author";

  const goBack = () => {
    setCurrentIndex(0);
  };

  const [selectedRows, setSelectedRows] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (index: number) => {
    setSelectedRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    const newSelectedRows = blogs.reduce((acc, _, index) => {
      acc[index] = newSelectAll;
      return acc;
    }, {} as { [key: number]: boolean });

    setSelectedRows(newSelectedRows);
  };

  const handleDelete = (index: number) => {
    dispatch(deleteBlog(blogs[index]._id));
  };

  return (
    <AppCard>
      {loading && <Spinner />}
      <div className="p-6 mt-16 w-full max-w-[1200px] mx-auto">
        <div className="pt-4">
          <Back action={goBack} />
        </div>

        <div className="mt-[50px]">
          <div className="text-center font-semibold text-[28px] leading-[54.82px] mb-[22px]">
            {`Manage ${authorName}'s Posts`}
          </div>
          <div className="flex gap-[18px] items-center mb-4">
            <div className="py-[13.5px] px-4 rounded-[7.87px] bg-inherit flex justify-between border border-[#776D65] w-[141px]">
              <select
                name="action"
                title="action"
                className="bg-inherit w-full text-sm font-medium"
              >
                <option value="">Bulk Action</option>
              </select>
            </div>
            <SecondaryButton
              style={{}}
              text="Apply"
              eventHandler={() => {}}
              type="button"
            />
          </div>
          <div className="rounded-[18.27px] bg-[#FEFEFE]">
            <table className="table-auto w-full">
              <thead className="h-[95px] border-b-[1.52px] border-b-[#26CCF0]">
                <tr className="">
                  <th className="">
                    <input
                      title="checkbox"
                      type="checkbox"
                      name=""
                      id=""
                      className="w-[50px]"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th
                    className="text-[18px] text-[#1E1E1E] font-medium"
                    style={{
                      width: "312px",
                    }}
                  >
                    Title
                  </th>
                  <th className="text-[18px] text-[#1E1E1E] font-medium">
                    Author
                  </th>
                  <th className="text-[18px] text-[#1E1E1E] font-medium">
                    Categories
                  </th>
                  <th className="text-[18px] text-[#1E1E1E] font-medium">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {blogs.filter((b) => b.author === selectedUser?._id).length >
                0 ? (
                  blogs
                    .filter((b) => b.author === selectedUser?._id)
                    .map((blog, index) => (
                      <tr
                        key={index}
                        className="min-h-[100px] border-b border-b-[#26CCF0]"
                      >
                        <td className="text-center ">
                          <input
                            title="checkbox"
                            type="checkbox"
                            name=""
                            id=""
                            checked={selectedRows[index] || false}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </td>
                        <td className="text-left text-[#1E1E1E] font-semibold text-sm">
                          {blog.title}
                          <AnimatePresence>
                            {selectedRows[index] && !Boolean(selectAll) && (
                              <motion.div
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: "auto" }}
                                exit={{ opacity: 0, x: "-100%" }}
                                transition={{ duration: 0.2 }}
                                key={`buttons-${index}`}
                                className="flex max-w-min mt-[12px] gap-[16px]"
                              >
                                <p
                                  className="text-sm text-[#010CA6] cursor-pointer"
                                  onClick={() => handleDelete(index)}
                                >
                                  View
                                </p>
                                <p
                                  className="text-sm text-[#D70900] cursor-pointer"
                                  onClick={() => handleDelete(index)}
                                >
                                  Delete
                                </p>
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </td>
                        <td className="text-center text-[#1E1E1E] font-medium text-sm">
                          {users.find((user) => user._id === blog.author)
                            ?.fullName || "Unknown Author"}
                        </td>
                        <td className="text-center  text-[#010CA6] font-normal text-sm">
                          {categories.find(
                            (category) => category._id === blog.category
                          )?.name || "Unknown Category"}
                        </td>
                        <td className="text-right  h-[100px] flex justify-end items-center text-[#776D65] font-normal text-sm mr-6">
                          Published <br />
                          {new Date(blog.releaseDate).toLocaleString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            hour12: true,
                            timeZoneName: "short",
                          })}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <div className="font-medium text-center text-[30px] w-full text-[#010CA6] py-6 bg-[#F6F6F6]">
                        {`No post from ${authorName} yet`}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div
              className={`text-center py-[14px] ${
                blogs.filter((b) => b.author === selectedUser?._id).length ===
                  0 && "border-t border-t-[#26CCF0]"
              }`}
            >
              Page 1 of 1
            </div>
          </div>
        </div>
      </div>
    </AppCard>
  );
};

export default ManageAuthorsPosts;
