import React, { useState } from "react";
import { VerifyEmailType } from "../../Utils/Types";
import { AuthInput } from "../../UI/Input/Input";
import { ValidateAuth } from "./ValidateAuth";
import { VerifyEmailHeroDetails } from "../../Utils/Utils";
import { AuthCard as Card } from "../Card";
import Hero from "./Hero";
import logo from "../../Assets/others/logo.png";
import Button from "../../UI/Button";
import api from "../../API/axios";
import { toast } from "sonner";
import Spinner from "../../UI/Spinner";
import { useNavigate } from "react-router-dom";

const VerifyEmail: React.FC = React.memo(() => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  // Input fields
  const [email, setEmail] = useState<string>("");

  // Error fields
  const [inputErrors, setInputErrors] = useState<VerifyEmailType>({
    email: {
      valid: false,
      message: "",
      context: "",
      type: "error",
    },
    none: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors = ValidateAuth(email, "verify_email");
    setInputErrors(errors);
    if (errors.none) {
      setLoading(true);
      try {
        const response = await api.post("/auth/forgot-pass", { email });
        if (response.status !== 200) {
          toast.error(response.data.message["1"]);
        } else {
          sessionStorage.setItem("otp-email", email);
          navigate("/verify-otp");
        }
      } catch (error: any) {
        console.error(error);
        toast.error(error.response.data.message["1"]);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Card>
      {loading && <Spinner />}
      <div className="w-full relative overflow-y-scroll my-6 px-6 hide-scrollbar">
        <img className="w-[160px] mx-auto" src={logo} alt="Logo" />
        <div className="w-full max-w-[500px] mx-auto relative top-[45%] translate-y-[-45%]">
          <h1 className="text-4xl font-bold mb-2">Forgot Password</h1>
          <p className="text-authGrey">Please enter your email to continue</p>

          <form className="flex flex-col gap-4 mt-10" onSubmit={handleSubmit}>
            <AuthInput
              label="Email"
              name="email"
              value={email}
              type="text"
              onChange={handleChange}
              error={inputErrors.email}
              placeholder="example234@gmail.com"
            />

            <Button
              style={{}}
              text="Verify"
              eventHandler={() => {}}
              type="submit"
            />
          </form>
        </div>
      </div>
      <Hero
        headerText={VerifyEmailHeroDetails.headerText}
        bodyText={VerifyEmailHeroDetails.bodyText}
      />
    </Card>
  );
});

export default VerifyEmail;
