import React, { createContext, ReactNode } from "react";

type AlertContextType = {};

export const AlertContext = createContext<AlertContextType>({});

export const AlertContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const returnValue: AlertContextType = {};

  return (
    <AlertContext.Provider value={returnValue}>
      {children}
    </AlertContext.Provider>
  );
};
