import { AlertContextProvider } from "./AlertContext";
import { AppContextProvider } from './AppContext'
import React, {ReactNode} from 'react'

const Provider:React.FC<{children: ReactNode}> = ({children}) => {
    return (
        <AlertContextProvider>
            <AppContextProvider>
                {children}
            </AppContextProvider>
        </AlertContextProvider>
    )
}

export default Provider