import React from "react";
import { RootState } from "../Store/Store";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

interface PrivilegesProps {
  role: string;
}

const Privileges: React.FC<PrivilegesProps> = ({ role }) => {
  const currentRole = useSelector((state: RootState) => state.auth.user?.role);
  const location = useLocation();

  return currentRole === role ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default Privileges;
