import React from "react";
import { AppChildren } from "../Utils/Types";
import { useSelector } from "react-redux";
import { RootState } from "../Store/Store";
import SideBar from "./SideBar";
import MenuBar from "./MenuBar";

export const AuthCard: React.FC<AppChildren> = React.memo(({ children }) => {
  return (
    <main className="w-full h-[100vh] grid grid-cols-2 rounded-2xl z-30">
      {children}
    </main>
  );
});

export const AppCard: React.FC<AppChildren> = React.memo(({ children }) => {
  const role = useSelector((state: RootState) => state.auth.user?.role);

  return (
    <main className="w-full min-h-[100vh]">
      <SideBar role={role} />
      <div className="w-dashboard-width float-right min-h-[100vh] relative bg-dashboardContrast">
        <MenuBar />
        {children}
      </div>
    </main>
  );
});
