import { useEffect, useState } from "react";
import ManageCategories from "./Manage";
import AddCategories from "./Add";
import EditCategory from "./Edit";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/Store";
import { getAllCategories } from "../../Store/Slices/CategorySlice";
import { getAllActiveBlogs } from "../../Store/Slices/BlogsSlice";

const CategoryIndex = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { categories, loading, success } = useSelector(
    (state: RootState) => state.categories
  );
  const { blogs } = useSelector((state: RootState) => state.blogs);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllActiveBlogs());
  }, [dispatch]);

  const getCategoriesDisplay = () => {
    switch (currentIndex) {
      case 0:
        return (
          <ManageCategories
            setCurrentIndex={setCurrentIndex}
            categories={categories}
            loading={loading}
            blogs={blogs}
          />
        );
      case 1:
        return (
          <AddCategories
            setCurrentIndex={setCurrentIndex}
            loading={loading}
            success={success}
          />
        );
      case 3:
        return <EditCategory setCurrentIndex={setCurrentIndex} />;
      default:
        return;
    }
  };

  return <>{getCategoriesDisplay()}</>;
};

export default CategoryIndex;
