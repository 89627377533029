// import { GoCommentDiscussion } from "react-icons/go";
// import { IoEyeOutline } from "react-icons/io5";
// import { FaRegEdit } from "react-icons/fa";
// import { Link } from "react-router-dom";
import { Blog as BlogInterface } from "../../Store/Slices/BlogsSlice";

interface BlogProps {
  blog: BlogInterface;
}
const Blog = ({ blog }: BlogProps) => {
  return (
    <li className="flex flex-row items-center gap-6 hover:bg-slate-100 rounded-[8px] cursor-pointer">
      <div className="w-[263px] h-[175px] rounded-[8px] overflow-hidden">
        <img
          src={blog.coverPhoto}
          alt="Blog"
          className="object-cover size-full"
        />
      </div>
      <div className="w-full flex flex-col gap-4">
        <h2 className="text-[1rem] font-semibold max-w-[741px]">
          {blog.title}
        </h2>
        {/* <div className="w-full flex flex-row gap-6 items-center">
          <div className="text-[0.85rem] text-authGrey font-medium flex flex-row items-center gap-1">
            <GoCommentDiscussion />
            10 Comments
          </div>
          <div className="text-[0.85rem] text-authGrey font-medium flex flex-row items-center gap-1">
            <IoEyeOutline />
            56 Views
          </div>
          <Link
            className="text-[0.85rem] text-authGrey font-medium, flex flex-row items-center gap-1"
            to=""
          >
            <FaRegEdit />
            Edit
          </Link>
        </div> */}
      </div>
    </li>
  );
};

export default Blog;
