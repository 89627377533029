import React, { useEffect } from "react";
import { AppCard } from "../Card";
import Button from "../../UI/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/Store";
import Spinner from "../../UI/Spinner";
import { deleteUser, User } from "../../Store/Slices/UsersSlice";

interface ManageUsersProps {
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  users: User[];
  loading: boolean;
  setSelectedUser: (user: User) => void;
}

const Manage = ({
  setCurrentIndex,
  users,
  loading,
  setSelectedUser,
}: ManageUsersProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const loggedInUser = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    console.log(loggedInUser);
  }, []);

  const handleDelete = (index: number) => {
    const userId = users[index]._id;
    dispatch(deleteUser(userId));
  };

  return (
    <AppCard>
      {loading && <Spinner />}
      <div className="p-6 mt-16 w-full max-w-[1200px] mx-auto">
        <div className="pt-4">
          <Button
            style={{
              width: "fit-content",
            }}
            text="Add New Users"
            eventHandler={() => setCurrentIndex(1)}
            type="button"
          />
        </div>

        <div className="mt-[50px]">
          <div className="text-center font-semibold text-[28px] leading-[54.82px] mb-7">
            Manage Users
          </div>
          <div className="rounded-[18.27px] bg-[#FEFEFE]">
            <table className="table-auto w-full">
              <thead className="h-[70px] border-b-[1.52px] border-b-[#26CCF0]">
                <tr className="">
                  <th className="text-[18px] text-[#1E1E1E] font-medium">N</th>
                  <th className="text-[18px] text-[#1E1E1E] font-medium w-[350px]">
                    Username
                  </th>
                  <th className="text-[18px] text-[#1E1E1E] font-medium">
                    Role
                  </th>
                  <th className="text-[18px] text-[#1E1E1E] font-medium w-[360px]">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0 ? (
                  users.map((user, index) => (
                    <tr key={index} className="border-b border-b-[#26CCF0]">
                      <td className="text-center text-[28px] leading-[54.82px] font-normal text-[#706E6E]">
                        {index + 1}
                      </td>
                      <td className="text-center">
                        <div className="flex gap-[12.18px] items-center text-[20px] font-medium leading-[54.82px] text-[#1E1E1E]">
                          <div className="w-14 h-14 my-3 rounded-full bg-[#26CCF096] border-[1.52px] border-[#26CCF0] overflow-hidden">
                            {user.avatar && (
                              <img
                                src={user.avatar}
                                alt=""
                                className="size-full border-[#26CCF0] object-cover object-center"
                              />
                            )}
                          </div>
                          <div className="whitespace-nowrap">
                            {user.fullName}
                          </div>
                        </div>
                      </td>
                      <td className="text-center capitalize text-[20px] leading-[54.82px] text-[#706E6E]">
                        {user.role}
                      </td>
                      <td className="text-right">
                        <div className="flex justify-end gap-12 w-fit ml-auto whitespace-nowrap mr-[30px] text-base">
                          <div
                            className="cursor-pointer text-[#1E6271]"
                            onClick={() => {
                              setSelectedUser(user);
                              setCurrentIndex(2);
                            }}
                          >
                            Manage Posts
                          </div>
                          {user._id !== loggedInUser?._id && (
                            <div
                              className="cursor-pointer text-[#FF0000]"
                              onClick={() => handleDelete(index)}
                            >
                              Delete
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <div className="font-medium text-center text-[30px] w-full text-[#010CA6] py-6 bg-[#F6F6F6]">
                        No users yet
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div
              className={`text-center py-[14px] ${
                users.length === 0 && "border-t border-t-[#26CCF0]"
              }`}
            >
              Page 1 of 1
            </div>
          </div>
        </div>
      </div>
    </AppCard>
  );
};

export default Manage;
