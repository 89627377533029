import React, { useState } from "react";
import { AppCard } from "../Card";
import Button, { SecondaryButton } from "../../UI/Button";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { Category, deleteCategory } from "../../Store/Slices/CategorySlice";
import Spinner from "../../UI/Spinner";
import { Blog } from "../../Store/Slices/BlogsSlice";

interface ManageCategoriesProps {
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  categories: Category[];
  loading: boolean;
  blogs: Blog[];
}

const Manage = ({
  setCurrentIndex,
  categories,
  loading,
  blogs,
}: ManageCategoriesProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const [selectedRows, setSelectedRows] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (index: number) => {
    if (!selectAll) {
      setSelectedRows({ [index]: !selectedRows[index] });
    } else {
      setSelectedRows({ [index]: true });
      setSelectAll(false);
    }
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    const newSelectedRows = categories.reduce((acc, _, index) => {
      acc[index] = newSelectAll;
      return acc;
    }, {} as { [key: number]: boolean });

    setSelectedRows(newSelectedRows);
  };

  const handleEdit = (index: number) => {
    // console.log("Edit category at index", index);
  };

  const handleDelete = (index: number) => {
    const categoryId = categories[index]._id;
    dispatch(deleteCategory(categoryId));
  };

  return (
    <AppCard>
      {loading && <Spinner />}
      <div className="p-6 mt-16">
        <div className="pt-4">
          <Button
            style={{
              width: "fit-content",
            }}
            text="Add New Category"
            eventHandler={() => {
              setCurrentIndex(1);
            }}
            type="button"
          />
        </div>

        <div className="mt-[50px]">
          <div className="text-center font-semibold text-[28px] leading-[54.82px] mb-7">
            Manage Categories
          </div>
          {/* <div className="flex gap-[18px] items-center mb-4">
            <div className="py-[13.5px] px-4 rounded-[7.87px] bg-inherit flex justify-between border border-[#776D65] w-[141px]">
              <select
                name=""
                id=""
                className="bg-inherit w-full text-sm font-medium"
              >
                <option value="">Bulk Action</option>
              </select>
            </div>
            <SecondaryButton
              style={{}}
              text="Apply"
              eventHandler={() => {}}
              type="button"
            />
          </div> */}

          <div className="rounded-[18.27px] bg-[#FEFEFE]">
            <table className="table-auto w-full">
              <thead className="h-[95px] border-b-[1.52px] border-b-[#26CCF0]">
                <tr>
                  <th>
                    {/* <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={selectAll}
                      onChange={handleSelectAll}
                    /> */}
                  </th>
                  <th className="text-[18px] text-[#1E1E1E] font-medium">
                    Title
                  </th>
                  <th className="text-[18px] text-[#1E1E1E] font-medium">
                    Description
                  </th>
                  <th className="text-[18px] text-[#1E1E1E] font-medium">
                    Count
                  </th>
                </tr>
              </thead>
              <tbody>
                {categories.length > 0 ? (
                  categories.map((category, index) => (
                    <React.Fragment key={index}>
                      <tr className="h-[69px] border-b border-b-[#26CCF0]">
                        <td className="text-center">
                          <input
                            type="checkbox"
                            checked={selectedRows[index] || false}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </td>
                        <td className="text-center text-[#1E1E1E] font-semibold text-sm max-w-[30px]">
                          {category.name}
                          <AnimatePresence>
                            {selectedRows[index] && !Boolean(selectAll) && (
                              <motion.div
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: "auto" }}
                                exit={{ opacity: 0, x: "-100%" }}
                                transition={{ duration: 0.2 }}
                                key={`buttons-${index}`}
                                className="flex max-w-min mx-auto mt-[12px] gap-[16px]"
                              >
                                <p
                                  className="text-sm text-[#010CA6] cursor-pointer"
                                  onClick={() => {
                                    handleEdit(index);
                                    setCurrentIndex(3);
                                  }}
                                >
                                  Edit
                                </p>
                                <p
                                  className="text-sm text-[#D70900] cursor-pointer"
                                  onClick={() => handleDelete(index)}
                                >
                                  Delete
                                </p>
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </td>
                        <td className="text-center text-[#1E1E1E] font-medium text-sm">
                          {category.description}
                        </td>
                        <td className="text-center text-[#010CA6] text-sm">
                          {
                            blogs.filter(
                              (blog) => blog.category === category._id
                            ).length
                          }
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <div className="font-medium text-center text-[30px] w-full text-[#010CA6] py-6 bg-[#F6F6F6]">
                        No categories yet
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div
              className={`text-center py-[14px] ${
                categories.length === 0 && "border-t border-t-[#26CCF0]"
              }`}
            >
              Page 1 of 1
            </div>
          </div>

          {/* <div className="pt-4">
            <Button
              style={{
                width: "fit-content",
              }}
              text="Save Changes"
              eventHandler={() => {}}
              type="button"
            />
          </div> */}
        </div>
      </div>
    </AppCard>
  );
};

export default Manage;
