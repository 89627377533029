import React from 'react'
import { useLocation } from 'react-router-dom'
import { HeroType } from '../../Utils/Types'
import { motion } from 'framer-motion'
import sign_up_hero from '../../Assets/heros/sign-up-hero.svg'
import sign_in_hero from '../../Assets/heros/sign-in-hero.svg'

const Hero: React.FC<HeroType> = ({ headerText, bodyText }) => {
    const { pathname } = useLocation()

    const isInRegisterPage = pathname === '/sign-up'
    const isInLoginPage = pathname === '/login'

    return (
        <div className="w-full relative h-full overflow-y-scroll hide-scrollbar bg-auth-hero-gradient bg-repeat text-white">
            <div className=" w-full max-w-[500px] mx-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex-col gap-4 py-12 px-6 ">
                <img
                    className="w-[90%] max-w-[300px] mx-auto aspect-square"
                    src={isInRegisterPage ? sign_up_hero : sign_in_hero}
                    alt='Hero'
                />
                {
                    isInRegisterPage ?
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            transition={{ duration: 0.35 }}
                        >
                            <p className="text-[3rem]">✨</p>
                        </motion.div>
                        : isInLoginPage ?
                            <motion.div
                                initial={{ opacity: 1, height: 'auto' }}
                                animate={{ opacity: 0, height: 0 }}
                                transition={{ duration: 0.35 }}
                            >
                                <p className="text-[3rem]">✨</p>
                            </motion.div>
                            :
                            <div />
                }
                <h1 className="text-4xl leading-relaxed- mb-5 mt-4 font-[600]">{headerText}</h1>
                <p className="leading-snug text-sm">{bodyText}</p>
            </div>
        </div>
    )
}

export default Hero