import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginErrorType, LoginInputType } from "../../Utils/Types";
import { AuthInput } from "../../UI/Input/Input";
import { ValidateAuth } from "./ValidateAuth";
import { LoginHeroDetails } from "../../Utils/Utils";
import { AuthCard as Card } from "../Card";
// import { Checkbox, FormControlLabel } from "@mui/material";
import Hero from "./Hero";
import logo from "../../Assets/others/logo.png";
import Button from "../../UI/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/Store";
import Spinner from "../../UI/Spinner";
import { loginUser } from "../../Store/Slices/Auth/AuthSlice";
import Modal from "../../UI/Modals/Modal";
import modalError from "../../Assets/others/modalError.svg";
import unauthorizedText from "../../Assets/others/unauthorizedText.svg";

const Login: React.FC = React.memo(() => {
  // Input fields
  const [inputValues, setInputValues] = useState<LoginInputType>({
    email: "",
    password: "",
  });

  // Error fields
  const [inputErrors, setInputErrors] = useState<LoginErrorType>({
    email: {
      valid: false,
      message: "",
      context: "",
      type: "error",
    },
    password: {
      valid: false,
      message: "",
      context: "",
      type: "error",
    },
    none: false,
  });

  const dispatch = useDispatch<AppDispatch>();
  const { loading, error } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInputValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors = ValidateAuth(inputValues, "login_validation");
    setInputErrors(errors);
    if (errors.none) {
      const resultAction = await dispatch(
        loginUser({ email: inputValues.email, password: inputValues.password })
      );
      if (loginUser.fulfilled.match(resultAction)) {
        const { role } = resultAction.payload;
        if (role === "admin") {
          navigate("/admin", { replace: true });
        } else {
          navigate("/user", { replace: true });
        }
      } else {
        const errorPayload = resultAction.payload;
  
        if (errorPayload && typeof errorPayload === "object" && "1" in errorPayload) {
          const errorMessage = (errorPayload as any)[1];
          if (errorMessage === "invalid email or password") {
            setIsModalOpen(true);
          }
        } else {
          console.error("Unexpected error payload:", errorPayload);
        }
      }
    }
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <Card>
      {loading && <Spinner />}
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        modalIcon={modalError}
        modalText="The username or password is incorrect, please try again"
        modalTextImage={unauthorizedText}
        firstButtonText="Forgot Password"
        secondButtonText="Create an account"
        firstButtonAction={() => {
          navigate("/verify-email");
        }}
        secondButtonAction={() => {
          navigate("/sign-up");
        }}
      />
      <div className="w-full relative overflow-y-scroll my-6 px-6 hide-scrollbar">
        <div className="w-full max-w-[500px] mx-auto">
          <img className="w-[160px] my-8 mx-auto" src={logo} alt="Logo" />

          <h1 className="text-4xl font-bold mb-2">Sign in</h1>
          <p className="text-authGrey">
            Please login to continue to your account.
          </p>

          <form className="flex flex-col gap-4 mt-10" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4">
              <AuthInput
                label="Email"
                name="email"
                value={inputValues.email}
                type="text"
                onChange={handleChange}
                placeholder="example234@gmail.com"
                error={inputErrors.email}
              />
              <AuthInput
                label="Password"
                name="password"
                value={inputValues.password}
                type="password"
                onChange={handleChange}
                placeholder=""
                error={inputErrors.password}
              />
            </div>

            <div className="flex flex-row items-center justify-between w-full">
              {/* <FormControlLabel
                control={
                  <Checkbox
                    onChange={handlePersistChange}
                    sx={{
                      "&.Mui-checked": {
                        color: "rgba(1, 12, 166, 1)",
                      },
                      fontFamily: "Poppins sans-serif",
                    }}
                  />
                }
                label={
                  <span className="font-poppins font-medium text-[0.9rem]">
                    Keep me logged in
                  </span>
                }
              /> */}
              <Link
                className="text-authRedirect underline font-bold"
                to="/verify-email"
              >
                Forgot Password?
              </Link>
            </div>

            <Button
              style={{}}
              text="Sign in"
              eventHandler={() => {}}
              type="submit"
            />
          </form>

          {/* <p className="text-center text-authGrey my-5">or</p>

          <Google /> */}

          <p className="text-authGrey text-center my-5">
            Need an account?{" "}
            <span>
              <Link
                className="text-authRedirect underline font-bold"
                to="/sign-up"
              >
                Create one
              </Link>
            </span>
          </p>
        </div>
      </div>
      <Hero
        headerText={LoginHeroDetails.headerText}
        bodyText={LoginHeroDetails.bodyText}
      />
    </Card>
  );
});

export default Login;
