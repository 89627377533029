import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../API/axios";
import { toast } from "sonner";

export interface User {
    _id: string;
    fullName: string;
    email: string;
    avatar?: string;
    gender: string;
    role: string;
    password?: string
}

interface UsersState {
    users: User[];
    loading: boolean;
    success: boolean;
    error: string | null;
}

const initialState: UsersState = {
    users: [],
    loading: false,
    success: false,
    error: null
}

export const getAllUsers = createAsyncThunk(
    'users/getAll',
    async (_, thunkAPI) => {
        try {
            const response = await api.get('/user/');
            const { data } = response
            return data.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message)
        }
    }
)

export const createUser = createAsyncThunk(
    'users/create',
    async (newUser: Omit<User, '_id'> & { password: string }, thunkAPI) => {
        try {
            const response = await api.post('/user/create', newUser);
            const { data } = response;
            return data.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message);
        }
    })

export const deleteUser = createAsyncThunk(
    'users/delete',
    async (userId: string, thunkAPI) => {
        try {
            await api.delete(`/user/delete/${userId}`);
            return userId
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message)
        }
    }
)

export const updateUser = createAsyncThunk(
    'users/update',
    async (updatedUser: Partial<User>, thunkAPI) => {
        try {
            const response = await api.patch(`/user/update`, updatedUser);
            const { data } = response;
            return data.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message);
        }
    }
);


const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        resetSuccess(state) {
            state.success = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle fetching all categories
            .addCase(getAllUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.users = action.payload;
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            // Handle creating a new user
            .addCase(createUser.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.users.push(action.payload);
                toast.success('User Created Successfully.')
            })
            .addCase(createUser.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload as string;
            })

            //Handle deleting a user
            .addCase(deleteUser.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.loading = false;
                state.users = state.users.filter((user) => user._id !== action.payload)
                toast.success('User Deleted Successfully.')
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            // Handle updating a user
            .addCase(updateUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                const index = state.users.findIndex((user) => user._id === action.payload._id);
                if (index !== -1) {
                    state.users[index] = action.payload;
                }
                toast.success('Edit Successful.')
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload as string;
            });
    },
})

export const { resetSuccess } = usersSlice.actions
export default usersSlice.reducer;
