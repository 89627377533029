// HERO
export const LoginHeroDetails = {
  headerText: "Welcome Back to Your Davkart Content Hub!",
  bodyText:
    "Log in to continue creating and managing engaging blogs for Davkart. Elevate our brand’s voice and streamline our content strategy. Let’s make an impact together.",
};

export const RegHeroDetails = {
  headerText: "Empower Davkart with Engaging Content",
  bodyText:
    "Join us and start creating impactful blogs for Davkart. Streamline your content creation and enhance Davkart's online presence. Get started in minutes.",
};

export const VerifyEmailHeroDetails = {
  headerText: "Reset Your Password🔒",
  bodyText:
    "Forgot your password? No worries! Enter your email below, and we’ll send you instructions to reset your password.",
};

// ROLES
export const ROLES = {
  ADMIN: "admin",
  USER: "author",
};

// DATE CONVERSION
export const getCalendarParams = (date) => {
  const newDate = new Date(date);

  return {
    day: newDate.getDate(),
    month: newDate.toLocaleString("en-US", { month: "long" }),
  };
};
