import React from "react";
import { ButtonType } from "../Utils/Types";
import { FaPlus } from "react-icons/fa6";

export const CreateButton: React.FC<ButtonType> = ({
  text,
  type,
  eventHandler,
}) => {
  return (
    <button
      className="text-primary p-2 rounded-md border-primary border-[0.1rem] flex flex-row items-center gap-1 font-semibold hover:bg-primary hover:text-white ease-in-out text-[16px] duration-500"
      type={type}
      onClick={eventHandler}
    >
      <FaPlus />
      {text}
    </button>
  );
};

export const SecondaryButton: React.FC<ButtonType> = ({
  text,
  type,
  eventHandler,
  style,
}) => {
  return (
    <button
      className="text-primary py-[10px] px-[16px] rounded-md border-primary border-[0.1rem] font-semibold hover:bg-primary hover:text-white ease-in-out duration-500 text-sm h-min text-[16px]"
      type={type}
      onClick={eventHandler}
      style={style}
    >
      {text}
    </button>
  );
};

const Button: React.FC<ButtonType> = ({ text, type, style, eventHandler }) => {
  return (
    <button
      // className="bg-primary border-[0.1rem] border-transparent text-white rounded-md py-3 px-[19px] hover:bg-transparent hover:border-primary cursor-pointer text-[16px] hover:text-primary ease-in-out duration-500 w-full font-bold"
      className="bg-primary text-white py-[10px] px-[16px] rounded-md border-primary border-[0.1rem] font-semibold hover:bg-white hover:text-primary ease-in-out duration-500 text-sm h-min text-[16px]"
      type={type}
      onClick={eventHandler}
      style={style}
    >
      {text}
    </button>
  );
};

export default Button;
