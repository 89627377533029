import React from "react";
import { AppCard } from "../Card";
import Button from "../../UI/Button";

const Edit: React.FC<{ setCurrentIndex: any }> = ({ setCurrentIndex }) => {
  return (
    <AppCard>
      <div className="p-6 mt-16">
        <div className="mt-[50px]">
          <div className="text-center font-semibold text-[28px] leading-[54.82px] mb-7">
            Edit Category
          </div>
          <div>
            <form action="" className="flex flex-col gap-6">
              <div className="flex flex-col">
                <label
                  htmlFor="category-name"
                  className="text-[20px] leading-[30px] text-[#344054]"
                >
                  Name
                </label>
                <textarea
                  name=""
                  id="category name"
                  rows={1}
                  className="rounded-[7.9px] p-4 border border-[#D0D5DD] resize-none font-semibold"
                  value="Tech"
                ></textarea>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="category-description"
                  className="text-[20px] leading-[30px] text-[#344054]"
                >
                  Description
                </label>
                <textarea
                  name=""
                  id="category-description"
                  rows={5}
                  className="rounded-[7.9px] p-4 border border-[#D0D5DD] resize-none"
                ></textarea>
              </div>
            </form>
          </div>
          <div className="pt-4">
            <Button
              style={{
                width: "fit-content",
              }}
              text="Save Changes"
              eventHandler={() => {
                setCurrentIndex(0);
              }}
              type="button"
            />
          </div>
        </div>
      </div>
    </AppCard>
  );
};

export default Edit;
