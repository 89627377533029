import React,{useState, useEffect} from 'react'
import DatePicker from 'react-datepicker';
import { MdCalendarMonth, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { DateParamsType } from '../Utils/Types';
import 'react-datepicker/dist/react-datepicker.module.css'
import { getCalendarParams } from '../Utils/Utils';

const DateFilter:React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [startDate, setStartDate] = useState<Date | null>(new Date());

  const [dateParams, setDateParams] = useState<DateParamsType>({
    day: 0,
    month: ''
  })

  useEffect(()=>{
    setDateParams(getCalendarParams(startDate))
  },[startDate])

  const handleOpening = () => {
    setIsOpen(!isOpen)
  }

  const forceClose = () => {
    setIsOpen(false)
  }

  return (
    <div className="relative flex flex-row items-center justify-center">
      <MdCalendarMonth color='rgba(180, 180, 180, 1)' size={35}/>
      {<p className="text-authGrey ml-2">Today</p>}
      <div className="flex flex-row items-center ml-3">
        <p className="font-semibold">{dateParams.month} {dateParams.day}</p>
        {!isOpen?
          <MdKeyboardArrowDown
              color='rgba(1, 12, 166, 1)' 
              size={30} cursor={'pointer'} 
              onClick={handleOpening} 
          />
        :
          <MdKeyboardArrowUp
              color='rgba(1, 12, 166, 1)' 
              size={30} cursor={'pointer'} 
              onClick={handleOpening} 
          />
        }
      </div>
      {isOpen && 
        <div className="absolute top-[70px] shadow-lg">
          <DatePicker
            onClickOutside={forceClose}
            onSelect={forceClose}
            inline
            selected={startDate} 
            onChange={(date) => setStartDate(date)} 
          />
        </div>
      }
    </div>
  )
}

export default DateFilter