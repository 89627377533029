import React, { useEffect } from "react";
import { AppCard as Card } from "./Card";
import { CreateButton } from "../UI/Button";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../Utils/Utils";
import PeriodOfTheDay from "../UI/PeriodOfTheDay";
import maleAuthor from "../Assets/heros/male-blogger.svg";
import femaleAuthor from "../Assets/heros/female-blogger.svg";
import book from "../Assets/others/book.svg";
import comment from "../Assets/others/comments.svg";
import Blogs from "./Blogs/Blogs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../Store/Store";
import { getAllActiveBlogs } from "../Store/Slices/BlogsSlice";
import Spinner from "../UI/Spinner";

const Dashboard: React.FC<{ role: string }> = ({ role }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { blogs, loading } = useSelector((state: RootState) => state.blogs);

  useEffect(() => {
    dispatch(getAllActiveBlogs());
  }, [dispatch]);

  const recentBlogs = blogs.slice().reverse().slice(0, 3);

  return (
    <Card>
      {loading && <Spinner />}
      <div className="p-6 mt-16">
        <div className="pt-4">
          <h1 className="text-2xl font-medium">Welcome Back! 👋</h1>
          <PeriodOfTheDay />
        </div>

        <div className="flex flex-row items-center gap-6 justify-center w-full mt-[5rem]">
          <div className="bg-white rounded-md h-[130px] p-4 w-full min-w-[280px] max-w-[350px] mr-10 relative">
            <h4 className="text-1xl font-medium">{user?.fullName}</h4>
            <p className="text-authGrey mb-4 mt-1">
              {role === ROLES.ADMIN ? "Admin" : "Writer / Author"}
            </p>
            <p className="text-authGrey font-medium">
              <span className="text-primary text-2xl font-semibold">
                {blogs.length}
              </span>{" "}
              Total Post{blogs.length > 1 && "s"}
            </p>
            <img
              className="absolute w-[180px] top-[-50px] right-[-50px]"
              src={user?.gender === "M" ? maleAuthor : femaleAuthor}
              alt="Author"
            />
          </div>

          <div className="bg-white rounded-md h-[130px] p-4 min-w-[260px] relative">
            <img
              className="absolute w-[50px] top-[-25px] left-6"
              src={book}
              alt="Posts"
            />
            <p className="text-authGrey font-medium text-1xl mt-8 mb-4">
              Total Post
            </p>
            <p className="text-primary text-2xl font-medium">
              {blogs.filter((blog) => blog.author === user?._id).length}
            </p>
          </div>

          <div className="bg-white rounded-md h-[130px] p-4 min-w-[260px] relative">
            <img
              className="absolute w-[50px] top-[-25px] left-6"
              src={comment}
              alt="Posts"
            />
            <p className="text-authGrey font-medium text-1xl mt-8 mb-4">
              Comments
            </p>
            <p className="text-primary text-2xl font-medium">1,025</p>
          </div>
        </div>

        <div className="w-full my-8 bg-white p-6 rounded-md min-h-[300px]">
          <div className="flex flex-row justify-between items-center">
            <h1 className="text-2xl font-semibold">Recent Blogs</h1>
            <CreateButton
              style={{}}
              text="Add New"
              eventHandler={() => {
                sessionStorage.setItem("postState", "add");
                navigate("posts");
              }}
              type="button"
            />
          </div>
          <Blogs blogs={recentBlogs} />
        </div>
      </div>
    </Card>
  );
};

export default Dashboard;
