import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../API/axios';

export type User = {
    _id: string;
    fullName: string;
    email: string;
    role: string;
    gender: string
    avatar?: string | null
} | null

interface AuthState {
    isLoggedIn: boolean;
    user: User
    token: string | null;
    loading: boolean;
    error: string | null;
}

const initialState: AuthState = {
    isLoggedIn: !!localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user') || 'null'),
    token: localStorage.getItem('token') || null,
    loading: false,
    error: null,
};

export const loginUser = createAsyncThunk(
    'auth/login',
    async (credentials: { email: string; password: string }, thunkAPI) => {
        try {
            const response = await api.post('/auth/login', credentials);
            const { data } = response;
            localStorage.setItem('token', data.data.token);
            return data.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message);
        }
    }
);

export const registerUser = createAsyncThunk(
    'auth/register',
    async (userData: { email: string; password: string; fullName: string; gender: string }, thunkAPI) => {
        try {
            const response = await api.post('/auth/register', userData);
            const { data } = response;
            return data.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message);
        }
    }
);

export const refreshUser = createAsyncThunk(
    'auth/refresh',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as { auth: AuthState };
        const token = state.auth.token;
        if (!token) {
            return thunkAPI.rejectWithValue('No token available');
        }

        try {
            const response = await api.get(`/auth/refresh/${token}`);
            const { data } = response;
            localStorage.setItem('token', data.data.token);
            return data.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message);
        }
    }
);


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.user = null;
            state.token = null;
            state.isLoggedIn = false
            localStorage.removeItem('token');
            localStorage.removeItem('user')
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
                state.token = action.payload.token;
                state.isLoggedIn = true
                localStorage.setItem('user', JSON.stringify(action.payload));
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
                state.isLoggedIn = false
            })
            .addCase(registerUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(refreshUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(refreshUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
                state.token = action.payload.token;
                localStorage.setItem('user', JSON.stringify(action.payload));
            })
            .addCase(refreshUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
