import React from "react";

const Spinner: React.FC = () => {
  return (
    <div className="w-full h-[100vh] fixed bg-modal top-0 left-0 bottom-0 right-0 z-[100000]">
      <div className="absolute w-[150px] h-[150px] top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] z-20 bg-white rounded-md">
        <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
          <svg className="animate-spin h-24 w-24" viewBox="0 0 100 100">
            <defs>
              <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="rgba(224, 226, 255, 1)" />
                <stop offset="100%" stopColor="rgba(1, 12, 166, 1)" />
              </linearGradient>
            </defs>
            <circle
              cx="50"
              cy="50"
              r="32"
              stroke="url(#grad)"
              strokeWidth="10"
              fill="none"
              strokeDasharray="283"
              strokeDashoffset="75"
              strokeLinecap="round"
              transform="rotate(-90 50 50)"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
