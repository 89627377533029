import React, { useEffect, useState } from "react";
import { AppCard } from "../Card";
import Button from "../../UI/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { MdOutlineCloudUpload } from "react-icons/md";
import api from "../../API/axios";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { Category } from "../../Store/Slices/CategorySlice";
import { createBlog, resetSuccess } from "../../Store/Slices/BlogsSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Spinner from "../../UI/Spinner";

// Validation schema using Yup
const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  body: yup.string().required("Content is required"),
  category: yup.string().required("Category is required"),
  cover: yup.mixed().required("Cover photo is required"),
});

interface AddBlogsProps {
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  categories: Category[];
  loading: boolean;
  success: boolean;
}

const Add = ({
  setCurrentIndex,
  categories,
  loading,
  success,
}: AddBlogsProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [photoPreview, setPhotoPreview] = useState<string | null>(null);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const uploadCoverPhoto = async (file: File) => {
    setUploadLoading(true);
    const formData = new FormData();
    formData.append("images", file);

    try {
      const response = await api.post("/user/upload", formData);
      return response.data.data[0].url;
    } catch (error) {
      console.error("Error uploading cover photo:", error);
      return null;
    } finally {
      setUploadLoading(false);
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => setPhotoPreview(reader.result as string);
      reader.readAsDataURL(file);

      const uploadedUrl = await uploadCoverPhoto(file);
      if (uploadedUrl) {
        setValue("cover", uploadedUrl);
      }
    }
  };

  const onSubmit = async (data: any) => {
    if (data.cover) {
      dispatch(
        createBlog({
          title: data.title,
          body: data.body,
          cover: data.cover,
          category: data.category,
        })
      );
    }
  };

  useEffect(() => {
    if (success) {
      setCurrentIndex(0);
      dispatch(resetSuccess());
    }
  }, [success, dispatch, setCurrentIndex]);

  return (
    <AppCard>
      {(loading || uploadLoading) && <Spinner />}
      <div className="p-6 mt-16">
        <div className="pt-4">
          <Button
            style={{ width: "fit-content" }}
            text="Manage Post"
            eventHandler={() => setCurrentIndex(0)}
            type="button"
          />
        </div>
        <div className="mt-[50px]">
          <div className="text-center font-semibold text-[28px] leading-[54.82px] mb-[22px]">
            New Post
          </div>
          <form
            className="flex flex-col gap-[24px]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="title"
                className="text-xl leading-[30px] text-[#344054]"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                className="border border-[#D0D5DD] rounded-[8px] text-xl"
                {...register("title")}
                style={{ padding: "11.85px 15.8px" }}
              />
              {errors.title && (
                <p className="text-red-500">{errors.title.message}</p>
              )}
            </div>

            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="content"
                className="text-xl leading-[30px] text-[#344054]"
              >
                Body
              </label>
              <ReactQuill
                value={watch("body")}
                onChange={(value) => setValue("body", value)}
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                    [{ align: [] }],
                  ],
                }}
              />
              {errors.body && (
                <p className="text-red-500">{errors.body.message}</p>
              )}
            </div>

            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="category"
                className="text-xl leading-[30px] text-[#344054]"
              >
                Category
              </label>
              <select
                id="category"
                className="border border-[#D0D5DD] bg-[#E7E7E7] rounded-[8px] p-3"
                {...register("category")}
              >
                {categories.length > 0 ? (
                  categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))
                ) : (
                  <option disabled>No categories available</option>
                )}
              </select>
              {errors.category && (
                <p className="text-red-500">{errors.category.message}</p>
              )}
            </div>

            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="coverPhoto"
                className="text-xl leading-[30px] text-[#344054]"
              >
                Cover Photo
              </label>
              <div
                className={`file-upload-area cursor-pointer border-2 custom-dash border-dashed border-[#1849D6] flex flex-col gap-3 rounded-[12px] text-center items-center ${
                  !photoPreview ? " py-[45px] w-full" : "p-[12px] w-fit"
                }`}
                onClick={() => document.getElementById("coverPhoto")?.click()}
              >
                <input
                  type="file"
                  id="coverPhoto"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                {photoPreview ? (
                  <img
                    src={photoPreview}
                    alt="Selected Cover"
                    className="w-[344px] h-[239px] rounded-[8px] mb-2 object-cover"
                  />
                ) : (
                  <MdOutlineCloudUpload className="text-4xl text-[#A5A8B1]" />
                )}
                <p className="text-[#A5A8B1] text-center">
                  Click to upload a cover photo.
                </p>
              </div>
              {errors.cover && (
                <p className="text-red-500">{errors.cover.message}</p>
              )}
            </div>

            <Button
              style={{ width: "fit-content" }}
              text="Create"
              type="submit"
            />
          </form>
        </div>
      </div>
    </AppCard>
  );
};

export default Add;
