import { useEffect, useState } from "react";
import ManageUsers from "./Manage";
import AddUsers from "./Add";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/Store";
import { getAllUsers, User } from "../../Store/Slices/UsersSlice";
import ManageAuthorsPosts from "./ManageUserPosts";
import { getAllCategories } from "../../Store/Slices/CategorySlice";

const UsersIndex = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const { users, loading, success } = useSelector(
    (state: RootState) => state.users
  );
  const { blogs } = useSelector((state: RootState) => state.blogs);
  const { categories } = useSelector((state: RootState) => state.categories);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllCategories());
  }, [dispatch]);

  const getUserDisplay = () => {
    switch (currentIndex) {
      case 0:
        return (
          <ManageUsers
            setCurrentIndex={setCurrentIndex}
            users={users}
            loading={loading}
            setSelectedUser={setSelectedUser}
          />
        );
      case 1:
        return (
          <AddUsers
            setCurrentIndex={setCurrentIndex}
            loading={loading}
            success={success}
          />
        );
      case 2:
        return (
          <ManageAuthorsPosts
            selectedUser={selectedUser}
            setCurrentIndex={setCurrentIndex}
            users={users}
            categories={categories}
            loading={loading}
            blogs={blogs}
          />
        );
      default:
        return;
    }
  };

  return <>{getUserDisplay()}</>;
};

export default UsersIndex;
