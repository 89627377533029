import styled from "@emotion/styled"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const StyledVisible = styled(VisibilityIcon)({
    color: 'rgba(154, 154, 154, 1)',
    width: '24px',
    cursor: 'pointer',
    position: 'absolute',
    right: '0.8rem'
})

export const StyledNotVisible = styled(VisibilityOffIcon)({
    color: 'rgba(154, 154, 154, 1)',
    width: '24px',
    cursor: 'pointer',
    position: 'absolute',
    right: '0.8rem'
})