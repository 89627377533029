import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { NavLinkType } from "../Utils/Types";
import { FaUsersCog } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BiSolidDashboard } from "react-icons/bi";
import { MdCategory } from "react-icons/md";
import { ROLES } from "../Utils/Utils";
import logo from "../Assets/others/logo.png";

const CustomNavLink: React.FC<NavLinkType> = ({ to, children }) => {
  const { pathname } = useLocation();
  const className = pathname.includes(to)
    ? "flex flex-row items-center gap-3 text-primary bg-white py-3 pl-8 w-[210px] font-medium rounded-tr-[1rem] rounded-br-[1rem]"
    : "flex flex-row items-center gap-3 text-white bg-transparent py-4 pl-8 font-medium";
  return (
    <NavLink to={to} className={className}>
      {children}
    </NavLink>
  );
};

const SideBar: React.FC<{ role: string | undefined }> = ({ role }) => {
  const { pathname } = useLocation();

  return (
    <div className="w-[230px] h-[100%] top-0 fixed bg-auth-hero-gradient">
      <img src={logo} alt="Logo" className="w-[160px] mt-[33px] ml-[32px]" />
      <nav className="mt-12">
        {role === ROLES.USER && (
          <>
            <NavLink
              to="/user"
              className={`${
                pathname === "/user"
                  ? "flex flex-row items-center gap-3 text-primary bg-white py-3 pl-8 w-[210px] font-medium rounded-tr-[1rem] rounded-br-[1rem]"
                  : "flex flex-row items-center gap-3 text-white bg-transparent py-4 pl-8 font-medium"
              }`}
            >
              <BiSolidDashboard size={25} />
              Dashboard
            </NavLink>
            <CustomNavLink to="/user/profile">
              <FaUsersCog size={25} />
              Manage Profile
            </CustomNavLink>
            <CustomNavLink to="/user/posts">
              <IoDocumentTextOutline size={25} />
              Manage Posts
            </CustomNavLink>
            <CustomNavLink to="/user/categories">
              <MdCategory size={25} />
              Categories
            </CustomNavLink>
          </>
        )}
        {role === ROLES.ADMIN && (
          <>
            <NavLink
              to="/admin"
              className={`${
                pathname === "/admin"
                  ? "flex flex-row items-center gap-3 text-primary bg-white py-3 pl-8 w-[210px] font-medium rounded-tr-[1rem] rounded-br-[1rem]"
                  : "flex flex-row items-center gap-3 text-white bg-transparent py-4 pl-8 font-medium"
              }`}
            >
              <BiSolidDashboard size={25} />
              Dashboard
            </NavLink>
            <CustomNavLink to="/admin/users">
              <FaUsersCog size={25} />
              Manage Users
            </CustomNavLink>
            <CustomNavLink to="/admin/profile">
              <FaUsersCog size={25} />
              Manage Profile
            </CustomNavLink>
            <CustomNavLink to="/admin/posts">
              <IoDocumentTextOutline size={25} />
              Manage Posts
            </CustomNavLink>
            <CustomNavLink to="/admin/categories">
              <MdCategory size={25} />
              Categories
            </CustomNavLink>
          </>
        )}
      </nav>
    </div>
  );
};

export default SideBar;
