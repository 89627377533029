import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../API/axios";
import { toast } from "sonner";

export interface Blog {
    _id: string;
    title: string;
    body: string;
    category: string;
    coverPhoto: string;
    releaseDate: string;
    status: string;
    author: string;
    comments: any[];
}

interface CreateBlogData {
    title: string;
    category: string;
    body: string;
    cover: string;
}

interface BlogState {
    blogs: Blog[];
    loading: boolean;
    status: string;
    success: boolean;
    error: string | null;
}

const initialState: BlogState = {
    blogs: [],
    loading: false,
    status: 'active',
    success: false,
    error: null
}

export const getAllActiveBlogs = createAsyncThunk(
    'blogs/getAllActive',
    async (_, thunkAPI) => {
        try {
            const response = await api.get('/blog/active')
            const { data } = response
            return data.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message)
        }
    }
)

export const createBlog = createAsyncThunk(
    'blogs/create',
    async (blogData: CreateBlogData, thunkAPI) => {
        try {
            const response = await api.post('/blog/create', blogData)
            const { data } = response
            return data.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message)
        }

    }
)

export const deleteBlog = createAsyncThunk(
    'blogs/softDelete',
    async (blogId: string, thunkAPI) => {
        try {
            await api.delete(`/blog/delete/${blogId}`)
            return blogId
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message)
        }
    }
)

export const editBlog = createAsyncThunk(
    'blog/edit',
    async ({ blogId, blogData }: { blogId: string | undefined; blogData: Partial<CreateBlogData> & Omit<CreateBlogData, 'category'> }, thunkAPI) => {
        try {
            const response = await api.patch(`/blog/edit/${blogId}`, blogData);
            const { data } = response;
            return data.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message);
        }
    }
);

const blogsSlice = createSlice({
    name: 'blogs',
    initialState,
    reducers: {
        resetSuccess(state) {
            state.success = false;
        },
    }, extraReducers: (builder) => {
        builder
            // Handle get all active blogs
            .addCase(getAllActiveBlogs.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(getAllActiveBlogs.fulfilled, (state, action) => {
                state.loading = false
                state.blogs = action.payload
            })
            .addCase(getAllActiveBlogs.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload as string
            })
            // Handle creating a blog
            .addCase(createBlog.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            })
            .addCase(createBlog.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.blogs.push(action.payload);
                toast.success('Post Created Successfully.');
            })
            .addCase(createBlog.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload as string;
            })
            // Handle blog soft delete
            .addCase(deleteBlog.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteBlog.fulfilled, (state, action) => {
                state.loading = false
                state.blogs = state.blogs.filter((blog) => blog._id !== action.payload)
                toast.success('Post Deleted.');
            })
            .addCase(deleteBlog.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            // Handle edit blog
            .addCase(editBlog.pending, (state) => {
                state.loading = true;
                state.success = false;
            })
            .addCase(editBlog.fulfilled, (state, action) => {
                state.loading = false;
                const index = state.blogs.findIndex((blog) => blog._id === action.payload._id);
                if (index !== -1) {
                    state.blogs[index] = action.payload;
                    state.success = true
                }
                toast.success('Post Edited.');
            })
            .addCase(editBlog.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload as string;
            });
    },
})

export const { resetSuccess } = blogsSlice.actions;
export default blogsSlice.reducer