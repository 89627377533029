import React, { useState, useEffect, useRef } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TbLogout } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../Store/Store";
import { logout } from "../Store/Slices/Auth/AuthSlice";
import { getAllUsers } from "../Store/Slices/UsersSlice";

const Profile: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const logoutRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { users } = useSelector((state: RootState) => state.users);
  const { user } = useSelector((state: RootState) => state.auth);

  const handleOpening = () => {
    setIsOpen(!isOpen);
  };

  const handleLogOut = () => {
    dispatch(logout());
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const currentUser = users.find((u) => u._id === user?._id);
  useEffect(() => {
    const listenForClick = (e: any) => {
      if (logoutRef && !logoutRef.current?.contains(e?.target)) {
        setIsOpen(false);
      }
    };
    window.addEventListener("click", listenForClick);
    return () => {
      window.removeEventListener("click", listenForClick);
    };
  }, []);

  return (
    <div className="relative flex flex-row items-center gap-1" ref={logoutRef}>
      <div className="bg-[#D9D9D9] rounded-full w-12 h-12 overflow-hidden border border-[#26CCF0]">
        {currentUser?.avatar && (
          <img
            src={currentUser?.avatar}
            alt="Profile"
            className="object-cover size-full"
          />
        )}
      </div>
      <MdKeyboardArrowDown
        color="rgba(1, 12, 166, 1)"
        size={30}
        cursor={"pointer"}
        onClick={handleOpening}
      />
      <div
        className={
          !isOpen
            ? "translate-y-[-150%] absolute w-auto"
            : " translate-y-0 bg-white w-auto cursor-pointer top-[70px] p-3 rounded-md shadow-md absolute flex flex-row items-center gap-4 text-primary hover:text-white hover:bg-primary ease-in-out duration-500"
        }
        onClick={handleLogOut}
      >
        <TbLogout />
        Logout
      </div>
    </div>
  );
};

export default Profile;
