import React, { useState } from "react";
import {
  ResetPasswordErrorType,
  ResetPasswordInputType,
} from "../../Utils/Types";
import { AuthInput } from "../../UI/Input/Input";
import { ValidateAuth } from "./ValidateAuth";
import { VerifyEmailHeroDetails } from "../../Utils/Utils";
import { AuthCard as Card } from "../Card";
import Hero from "./Hero";
import logo from "../../Assets/others/logo.png";
import Button from "../../UI/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../UI/Spinner";
import api from "../../API/axios";
import { toast } from "sonner";

const Reset: React.FC = React.memo(() => {
  // Input fields
  const [inputValues, setInputValues] = useState<ResetPasswordInputType>({
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);

  // Error fields
  const [inputErrors, setInputErrors] = useState<ResetPasswordErrorType>({
    password: {
      valid: false,
      message: "",
      context: "",
      type: "error",
    },
    confirmPassword: {
      valid: false,
      message: "",
      context: "",
      type: "error",
    },
    none: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInputValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors = ValidateAuth(inputValues, "reset");
    setInputErrors(errors);
    if (errors.none) {
      setLoading(true);
      try {
        const response = await api.post(
          "/auth/reset-pass",
          {
            password: inputValues.password,
          },
          {
            headers: {
              Authorization: `Bearer ${state.otpToken}`,
            },
          }
        );
        if (response.status !== 200) {
          toast.error(response.data.message["1"]);
        } else {
          navigate("/login");
          setTimeout(() => {
            toast.success("Password has been successfully reset");
          });
        }
      } catch (error: any) {
        console.error(error);
        toast.error(error.response.data.message["1"]);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Card>
      {loading && <Spinner />}
      <div className="w-full relative overflow-y-scroll my-6 px-6 hide-scrollbar">
        <img className="w-[160px] mx-auto" src={logo} alt="Logo" />
        <div className="w-full max-w-[500px] mx-auto relative top-[45%] translate-y-[-45%]">
          <h1 className="text-4xl font-bold mb-2">Set New Password</h1>
          <p className="text-authGrey">
            For security reasons, write a different password to your old one.
          </p>

          <form
            className="flex flex-col gap-[32px] mt-10"
            onSubmit={handleSubmit}
          >
            <AuthInput
              label="Password"
              name="password"
              value={inputValues.password}
              type="password"
              onChange={handleChange}
              placeholder=""
              error={inputErrors.password}
            />
            <AuthInput
              label="Confirm password"
              name="confirmPassword"
              value={inputValues.confirmPassword}
              type="password"
              onChange={handleChange}
              placeholder=""
              error={inputErrors.confirmPassword}
            />
            <Button
              style={{}}
              text="Confirm"
              eventHandler={() => {}}
              type="submit"
            />
          </form>
        </div>
      </div>
      <Hero
        headerText={VerifyEmailHeroDetails.headerText}
        bodyText={VerifyEmailHeroDetails.bodyText}
      />
    </Card>
  );
});

export default Reset;
