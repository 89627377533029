import React from "react";
import empty from "../../Assets/others/empty-state.svg";
import Blog from "./Blog";
import { Blog as BlogInterface } from "../../Store/Slices/BlogsSlice";

interface BlogsProps {
  blogs: BlogInterface[];
}
const Blogs = ({ blogs }: BlogsProps) => {
  let isEmpty = blogs.length === 0;
  return (
    <div className="w-full mt-12">
      {isEmpty ? (
        <img src={empty} alt="Empty" className="mx-auto" />
      ) : (
        <ul className="flex flex-col gap-4">
          {blogs.map((blog, index) => {
            return <Blog key={index} blog={blog} />;
          })}
        </ul>
      )}
    </div>
  );
};

export default Blogs;
