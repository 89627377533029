import { useState, useEffect } from "react";
import ManagePost from "./Manage";
import AddPost from "./Add";
import EditPost from "./Edit";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/Store";
import { getAllUsers } from "../../Store/Slices/UsersSlice";
import { getAllCategories } from "../../Store/Slices/CategorySlice";
import { Blog, getAllActiveBlogs } from "../../Store/Slices/BlogsSlice";

const BlogIndex: React.FC<{ isControlled: boolean }> = ({ isControlled }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useSelector((state: RootState) => state.auth);
  const { categories } = useSelector((state: RootState) => state.categories);
  const { users } = useSelector((state: RootState) => state.users);
  const { loading, blogs, success } = useSelector(
    (state: RootState) => state.blogs
  );
  const [selectedBlog, setSelectedBlog] = useState<Blog>();

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllCategories());
    dispatch(getAllActiveBlogs());
  }, [dispatch]);

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const filteredBlogs = blogs.filter((blog) => {
    if (user?.role === "admin") {
      return true;
    }
    if (user?.role === "author") {
      return blog.author === user._id;
    }
    return false;
  });

  const getBlogsDisplay = () => {
    switch (currentIndex) {
      case 0:
        return (
          <ManagePost
            setCurrentIndex={setCurrentIndex}
            user={user}
            users={users}
            categories={categories}
            loading={loading}
            blogs={filteredBlogs.slice().reverse()}
            setSelectedBlog={setSelectedBlog}
          />
        );
      case 1:
        return (
          <AddPost
            setCurrentIndex={setCurrentIndex}
            categories={categories}
            loading={loading}
            success={success}
          />
        );
      case 2:
        return (
          <EditPost
            setCurrentIndex={setCurrentIndex}
            categories={categories}
            loading={loading}
            success={success}
            selectedBlog={selectedBlog}
          />
        );
      default:
        return;
    }
  };

  return <>{getBlogsDisplay()}</>;
};

export default BlogIndex;
