import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
// import { modalType } from "../../Utils/Types";
import close from "../../Assets/others/close.svg";
import Button, { SecondaryButton } from "../Button";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  modalIcon: string;
  modalTextImage: string;
  modalText: string;
  firstButtonText: string;
  secondButtonText: string;
  firstButtonAction: () => void;
  secondButtonAction: () => void;
}

const Modal = ({
  isOpen,
  closeModal,
  modalIcon,
  modalTextImage,
  modalText,
  firstButtonText,
  secondButtonText,
  firstButtonAction,
  secondButtonAction,
}: ModalProps) => {
  const [modalElement] = useState(document.createElement("div"));

  useEffect(() => {
    document.body.appendChild(modalElement);
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.removeChild(modalElement);
      document.body.style.overflow = "";
    };
  }, [isOpen, modalElement]);

  const handleOutsideClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return isOpen
    ? ReactDOM.createPortal(
        <div
          className="fixed inset-0 bg-[#00000040] flex items-center justify-center z-50"
          role="dialog"
          aria-modal="true"
          onClick={handleOutsideClick}
        >
          <div
            className="bg-white py-10 rounded-[12px] shadow-lg relative flex items-center justify-center"
            style={{ minWidth: "612px" }}
          >
            <img
              src={close}
              alt=""
              className="absolute top-2 right-2 cursor-pointer"
              onClick={closeModal}
            />
            <div className="flex flex-col gap-[90px] max-w-[414px]">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-[60px] items-center">
                  <img src={modalIcon} alt="" className="size-[111px]" />
                  <img src={modalTextImage} alt="" className="max-w-[310px]" />
                </div>
                <p className="text-center text-[20px] max-w-[384px]">
                  {modalText}
                </p>
              </div>
              <div className="gap-5 flex">
                <SecondaryButton
                  text={firstButtonText}
                  type="button"
                  eventHandler={firstButtonAction}
                  style={{ flex: 1 }}
                />
                <Button
                  text={secondButtonText}
                  type="button"
                  eventHandler={secondButtonAction}
                  style={{ flex: 1 }}
                />
              </div>
            </div>
          </div>
        </div>,
        modalElement
      )
    : null;
};

export default Modal;
