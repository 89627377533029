import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RegErrorType, RegInputType } from "../../Utils/Types";
import { AuthInput } from "../../UI/Input/Input";
import { ValidateAuth } from "./ValidateAuth";
import { RegHeroDetails } from "../../Utils/Utils";
import { AuthCard as Card } from "../Card";
import Hero from "./Hero";
import logo from "../../Assets/others/logo.png";
import Button from "../../UI/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/Store";
import Spinner from "../../UI/Spinner";
import { registerUser } from "../../Store/Slices/Auth/AuthSlice";

const Register: React.FC = React.memo(() => {
  // Input fields
  const [inputValues, setInputValues] = useState<RegInputType>({
    name: "",
    email: "",
    username: "",
    gender: "",
    password: "",
    confirmPassword: "",
  });

  // Error fields
  const [inputErrors, setInputErrors] = useState<RegErrorType>({
    name: {
      valid: false,
      message: "",
      context: "",
      type: "error",
    },
    email: {
      valid: false,
      message: "",
      context: "",
      type: "error",
    },
    username: {
      valid: false,
      message: "",
      context: "",
      type: "error",
    },
    gender: {
      valid: false,
      message: "",
      context: "",
      type: "error",
    },
    password: {
      valid: false,
      message: "",
      context: "",
      type: "error",
    },
    confirmPassword: {
      valid: false,
      message: "",
      context: "",
      type: "error",
    },
    none: false,
  });

  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((state: RootState) => state.auth);
  // const loading = true;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInputValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors = ValidateAuth(inputValues, "register_validation");
    setInputErrors(errors);
    if (errors.none) {
      await dispatch(
        registerUser({
          email: inputValues.email,
          password: inputValues.password,
          fullName: inputValues.name,
          gender: inputValues.gender,
        })
      );
    } else {
    }
  };

  return (
    <Card>
      {loading && <Spinner />}
      <div className="w-full relative overflow-y-scroll my-6 px-6 hide-scrollbar">
        <div className="w-full max-w-[500px] mx-auto">
          <img className="w-[160px] my-8 mx-auto" src={logo} alt="Logo" />

          <h1 className="text-4xl font-bold mb-2">Sign up</h1>
          <p className="text-authGrey">Sign up and blog for Davkart! 😊</p>

          <form className="flex flex-col gap-4 mt-10" onSubmit={handleSubmit}>
            <AuthInput
              label="Your name"
              name="name"
              value={inputValues.name}
              type="text"
              onChange={handleChange}
              placeholder="John Doe"
              error={inputErrors.name}
            />
            <AuthInput
              label="Email"
              name="email"
              value={inputValues.email}
              type="text"
              onChange={handleChange}
              placeholder="example234@gmail.com"
              error={inputErrors.email}
            />
            <AuthInput
              label="Username"
              name="username"
              value={inputValues.username}
              type="text"
              onChange={handleChange}
              placeholder="stannyj999"
              error={inputErrors.username}
            />
            <AuthInput
              label="Gender"
              name="gender"
              value={inputValues.gender}
              type="text"
              onChange={handleChange}
              placeholder="Select Gender"
              error={inputErrors.gender}
            />

            <div
              className="flex flex-col gap-4"
              // initial={{ opacity: 0, height: 0 }}
              // animate={{ opacity: 1, height: 'auto' }}
              // transition={{ duration: 0.35 }}
            >
              <AuthInput
                label="Password"
                name="password"
                value={inputValues.password}
                type="password"
                onChange={handleChange}
                placeholder=""
                error={inputErrors.password}
              />
              <AuthInput
                label="Confirm password"
                name="confirmPassword"
                value={inputValues.confirmPassword}
                type="password"
                onChange={handleChange}
                placeholder=""
                error={inputErrors.confirmPassword}
              />
            </div>
            <Button
              style={{}}
              text="Sign up"
              eventHandler={() => {}}
              type="submit"
            />
          </form>

          <p className="text-authGrey text-center my-5">
            Already have an account?{" "}
            <span>
              <Link
                className="text-authRedirect underline font-bold"
                to="/login"
              >
                Sign in
              </Link>
            </span>
          </p>
        </div>
      </div>
      <Hero
        headerText={RegHeroDetails.headerText}
        bodyText={RegHeroDetails.bodyText}
      />
    </Card>
  );
});

export default Register;
