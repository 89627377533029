import React, { useState } from "react";
import { HiOutlineBell } from "react-icons/hi";
import SearchBar from "../UI/SearchBar";
import DateFilter from "../UI/DateFilter";
import Profile from "../UI/Profile";
import Notifications from "../UI/Notification/Notifications";

const MenuBar: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpening = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="w-dashboard-width py-3 px-[5%] fixed bg-white flex flex-row items-center z-10 shadow-sm justify-between">
      {/* <SearchBar /> */}
      <DateFilter />
      <div className="flex flex-row items-center gap-5">
        <div className="relative">
          <HiOutlineBell
            color="rgba(1, 12, 166, 1)"
            cursor="pointer"
            onClick={handleOpening}
            size={30}
          />
          <div className="absolute" />
        </div>
        <Profile />
      </div>
      {isOpen && <Notifications />}
    </div>
  );
};

export default MenuBar;
