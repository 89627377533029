import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../API/axios';
import { toast } from 'sonner';

export interface Category {
    _id: string;
    name: string;
    description: string;
}

interface CategoriesState {
    categories: Category[];
    loading: boolean;
    success: boolean;
    error: string | null;
}

const initialState: CategoriesState = {
    categories: [],
    loading: false,
    success: false,
    error: null,
};

export const getAllCategories = createAsyncThunk(
    'categories/getAll',
    async (_, thunkAPI) => {
        try {
            const response = await api.get('/category/');
            const { data } = response;
            return data.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message);
        }
    }
);

export const createCategory = createAsyncThunk(
    'categories/create',
    async (categoryData: { name: string; description: string }, thunkAPI) => {
        try {
            const response = await api.post('/category/create', categoryData);
            const { data } = response;
            return data.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message);
        }
    }
);

export const deleteCategory = createAsyncThunk(
    'categories/delete',
    async (categoryId: string, thunkAPI) => {
        try {
            await api.delete(`/category/delete/${categoryId}`);
            return categoryId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message);
        }
    }
);

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        resetSuccess(state) {
            state.success = false;
        },
    }, extraReducers: (builder) => {
        builder
            // Handle fetching all categories
            .addCase(getAllCategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.categories = action.payload;
            })
            .addCase(getAllCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            // Handle creating a category
            .addCase(createCategory.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            })
            .addCase(createCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.categories.push(action.payload);
                toast.success('New Category Added')
            })
            .addCase(createCategory.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload as string;
            })

            // Handle deleting a category
            .addCase(deleteCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.categories = state.categories.filter(
                    (category) => category._id !== action.payload
                );
                toast.success('Category Deleted')
            })
            .addCase(deleteCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { resetSuccess } = categoriesSlice.actions;
export default categoriesSlice.reducer;
