import React, { useEffect } from "react";
import { AppCard } from "../Card";
import Button from "../../UI/Button";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { createCategory, resetSuccess } from "../../Store/Slices/CategorySlice";
import Spinner from "../../UI/Spinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Validation schema using yup
const categorySchema = yup.object().shape({
  name: yup
    .string()
    .required("Category name is required")
    .min(3, "Name must be at least 3 characters"),
  description: yup
    .string()
    .required("Description is required")
    .min(10, "Description must be at least 10 characters"),
});

type CategoryFormData = {
  name: string;
  description: string;
};

interface AddCategoriesProps {
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  success: boolean;
}

const Add = ({ setCurrentIndex, loading, success }: AddCategoriesProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CategoryFormData>({
    resolver: yupResolver(categorySchema),
  });

  const onSubmit = (data: CategoryFormData) => {
    dispatch(createCategory(data));
    reset(); // Reset form fields after submission
  };

  useEffect(() => {
    if (success) {
      setCurrentIndex(0);
      dispatch(resetSuccess());
    }
  }, [success, dispatch, setCurrentIndex]);

  return (
    <AppCard>
      {loading && <Spinner />}
      <div className="p-6 mt-16">
        {/* Button to navigate back to Manage Categories */}
        <div className="pt-4">
          <Button
            style={{ width: "fit-content" }}
            text="Manage Categories"
            eventHandler={() => setCurrentIndex(0)}
            type="button"
          />
        </div>

        <div className="mt-[50px]">
          <div className="text-center font-semibold text-[28px] leading-[54.82px] mb-7">
            Add New Category
          </div>

          {/* Form for Adding Category */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-6"
          >
            {/* Category Name */}
            <div className="flex flex-col">
              <label
                htmlFor="category-name"
                className="text-[20px] leading-[30px] text-[#344054]"
              >
                Name
              </label>
              <input
                type="text"
                id="category-name"
                className="rounded-[7.9px] p-4 border border-[#D0D5DD]"
                {...register("name")}
              />
              {errors.name && (
                <p className="text-red-500 text-sm">{errors.name.message}</p>
              )}
            </div>

            {/* Category Description */}
            <div className="flex flex-col">
              <label
                htmlFor="category-description"
                className="text-[20px] leading-[30px] text-[#344054]"
              >
                Description
              </label>
              <textarea
                id="category-description"
                rows={5}
                className="rounded-[7.9px] p-4 border border-[#D0D5DD] resize-none"
                {...register("description")}
              ></textarea>
              {errors.description && (
                <p className="text-red-500 text-sm">
                  {errors.description.message}
                </p>
              )}
            </div>

            {/* Submit Button */}
            <div className="pt-4">
              <Button
                style={{ width: "fit-content" }}
                text="Add Category"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </AppCard>
  );
};

export default Add;
