// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Slices/Auth/AuthSlice'
import categoriesReducer from './Slices/CategorySlice'
import usersReducer from './Slices/UsersSlice'
import blogsReducer from './Slices/BlogsSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    categories: categoriesReducer,
    users: usersReducer,
    blogs: blogsReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
