export const ValidateAuth = (data, type) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  let errors = {
    name: {
      valid: true,
      message: "",
      context: "",
      type: "error",
    },
    email: {
      valid: true,
      message: "",
      context: "",
      type: "error",
    },
    otp: {
      valid: true,
      message: "",
      context: "",
      type: "error",
    },
    gender: {
      valid: true,
      message: "",
      context: "",
      type: "error",
    },
    username: {
      valid: true,
      message: "",
      context: "",
      type: "error",
    },
    password: {
      valid: true,
      message: "",
      context: "",
      type: "error",
    },
    confirmPassword: {
      valid: true,
      message: "",
      context: "",
      type: "error",
    },
    none: false,
  };

  if (type === "register_validation") {
    const { name, email, gender, username, password, confirmPassword } = data;
    if (name === "") {
      errors.name.message = "Field cannot be empty.";
    }
    if (!emailRegex.test(email)) {
      errors.email.message = "Email must be valid.";
    }
    if (gender === "") {
      errors.gender.message = "Field cannot be empty.";
    }
    if (username === "") {
      errors.username.message = "Field cannot be empty.";
    }
    if (password === "") {
      errors.password.message = "Field cannot be empty.";
    }
    if (confirmPassword === "") {
      errors.confirmPassword.message = "Field cannot be empty.";
    }
    if (password !== confirmPassword) {
      errors.password.message = "Passwords don't match.";
      errors.confirmPassword.message = "Passwords don't match.";
    }

    if (
      Object.values(data).every((input) => input !== "") &&
      emailRegex.test(email) &&
      password === confirmPassword
    ) {
      errors.none = true;
    }
  } else if (type === "login_validation") {
    const { email, password } = data;
    if (!emailRegex.test(email)) {
      errors.email.message = "Email must be valid.";
    }
    if (password === "") {
      errors.password.message = "Field cannot be empty.";
    }

    if (
      Object.values(data).every((input) => input !== "") &&
      emailRegex.test(email)
    ) {
      errors.none = true;
    }
  } else if (type === "verify_email") {
    if (!emailRegex.test(data)) {
      errors.email.message = "Email must be valid.";
    } else {
      errors.none = true;
    }
  } else if (type === "verify_otp") {
    if (data.length < 6) {
      errors.otp.message = "OTP Must be 6 characters long,";
    } else {
      errors.none = true;
    }
  } else if (type === "reset") {
    const { password, confirmPassword } = data;
    if (password === "") {
      errors.password.message = "Field cannot be empty.";
    }
    if (confirmPassword === "") {
      errors.confirmPassword.message = "Field cannot be empty.";
    }
    if (password !== confirmPassword) {
      errors.password.message = "Passwords don't match.";
      errors.confirmPassword.message = "Passwords don't match.";
    }

    if (
      Object.values(data).every((input) => input !== "") &&
      password === confirmPassword
    ) {
      errors.none = true;
    }
  }

  return errors;
};
