import React, { useEffect, useState } from "react";
import { AppCard } from "../Card";
import Button from "../../UI/Button";
import { MdOutlineCloudUpload } from "react-icons/md";
import eye from "../../Assets/others/eye.svg";
import api from "../../API/axios";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { createUser, resetSuccess } from "../../Store/Slices/UsersSlice";
import Spinner from "../../UI/Spinner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Validation schema using yup
const schema = yup.object().shape({
  username: yup.string().required("Username is required."),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required."),
  gender: yup.string().required("Gender is required."),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters long")
    .required("Password is required."),
  role: yup.string().required("Role is required."),
  coverPhoto: yup.mixed().required("Cover photo is required."),
});

interface AddUsersProps {
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  success: boolean;
}

const Add = ({ setCurrentIndex, loading, success }: AddUsersProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [viewPassword, setViewPassword] = useState(false);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [photoPreview, setPhotoPreview] = useState<string | null>(null);

  const togglePassword = () => setViewPassword((prev) => !prev);

  const handleUploadClick = () => {
    const inputElement = document.getElementById(
      "coverPhoto"
    ) as HTMLInputElement | null;
    if (inputElement) inputElement.click();
  };

  const uploadCoverPhoto = async (file: File) => {
    setUploadLoading(true);
    const formData = new FormData();
    formData.append("images", file);

    try {
      const response = await api.post("/user/upload", formData);
      return response.data.data[0].url;
    } catch (error) {
      console.error("Error uploading cover photo:", error);
      return null;
    } finally {
      setUploadLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setValue("coverPhoto", file);
      const reader = new FileReader();
      reader.onloadend = () => setPhotoPreview(reader.result as string);
      reader.readAsDataURL(file);

      const uploadedUrl = await uploadCoverPhoto(file);
      setValue("coverPhoto", uploadedUrl || "");
    }
  };

  const onSubmit = async (data: any) => {
    if (data.coverPhoto) {
      dispatch(
        createUser({
          fullName: data.username,
          email: data.email,
          password: data.password,
          role: data.role,
          gender: data.gender,
          avatar: data.coverPhoto,
        })
      );
    }
  };

  useEffect(() => {
    if (success) {
      setCurrentIndex(0);
      dispatch(resetSuccess());
    }
  }, [success, dispatch, setCurrentIndex]);

  return (
    <AppCard>
      {(loading || uploadLoading) && <Spinner />}
      <div className="p-6 mt-16 w-full max-w-[1200px] mx-auto">
        <div className="pt-4">
          <Button
            style={{ width: "fit-content" }}
            text="Manage Users"
            eventHandler={() => setCurrentIndex(0)}
            type="button"
          />
        </div>
        <div className="mt-[50px]">
          <div className="text-center font-semibold text-[28px] leading-[54.82px] mb-[22px]">
            Create User
          </div>
          <form
            className="flex flex-col gap-[24px] w-full max-w-[700px] mx-auto"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* Username Field */}
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="username"
                className="text-xl leading-[30px] text-[#344054]"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                {...register("username")}
                className={`border rounded-[8px] text-xl ${
                  errors.username ? "border-red-500" : "border-[#D0D5DD]"
                }`}
                style={{ padding: "11.85px 15.8px" }}
              />
              {errors.username && (
                <p className="text-red-500 text-sm">
                  {errors.username.message}
                </p>
              )}
            </div>

            {/* Email Field */}
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="email"
                className="text-xl leading-[30px] text-[#344054]"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                {...register("email")}
                className={`border rounded-[8px] text-xl ${
                  errors.email ? "border-red-500" : "border-[#D0D5DD]"
                }`}
                style={{ padding: "11.85px 15.8px" }}
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email.message}</p>
              )}
            </div>

            {/* Gender Field */}
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="gender"
                className="text-xl leading-[30px] text-[#344054]"
              >
                Gender
              </label>
              <div
                className={`border rounded-[8px] p-3 ${
                  errors.gender ? "border-red-500" : "border-[#D0D5DD]"
                }`}
              >
                <select
                  // name="gender"
                  id="gender"
                  className="bg-transparent w-full border-none outline-none"
                  {...register("gender")}
                >
                  <option value="" disabled hidden>
                    ----
                  </option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
              </div>
              {errors.gender && (
                <p className="text-red-500 text-sm">{errors.gender.message}</p>
              )}
            </div>

            {/* Password Field */}
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="password"
                className="text-xl leading-[30px] text-[#344054]"
              >
                Password
              </label>
              <div className="border border-[#D0D5DD] bg-white rounded-[8px] p-3 flex justify-between">
                <input
                  type={viewPassword ? "text" : "password"}
                  id="password"
                  {...register("password")}
                  className="bg-transparent rounded-[8px] text-xl w-9/12"
                />
                <img
                  src={eye}
                  alt="Toggle Password Visibility"
                  className="cursor-pointer"
                  onClick={togglePassword}
                />
              </div>
              {errors.password && (
                <p className="text-red-500 text-sm">
                  {errors.password.message}
                </p>
              )}
            </div>

            {/* Role Field */}
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="role"
                className="text-xl leading-[30px] text-[#344054]"
              >
                Role
              </label>
              <div
                className={`border rounded-[8px] p-3 ${
                  errors.role ? "border-red-500" : "border-[#D0D5DD]"
                }`}
              >
                <select
                  // name="role"
                  id="role"
                  className="bg-transparent w-full border-none outline-none"
                  {...register("role")}
                >
                  <option value="" disabled hidden>
                    ----
                  </option>
                  <option value="admin">Admin</option>
                  <option value="author">Author</option>
                </select>
              </div>
              {errors.role && (
                <p className="text-red-500 text-sm">{errors.role.message}</p>
              )}
            </div>

            {/* Cover Photo Field */}
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="coverPhoto"
                className="text-xl leading-[30px] text-[#344054]"
              >
                Cover Photo
              </label>
              <div
                className={`file-upload-area cursor-pointer border-2 custom-dash border-dashed border-[#1849D6] flex flex-col gap-3 rounded-[12px] text-center items-center ${
                  !photoPreview ? " py-[45px] w-full" : "p-[12px] w-fit"
                }`}
                onClick={handleUploadClick}
              >
                {photoPreview ? (
                  <img
                    src={photoPreview}
                    alt="uploaded cover"
                    className="w-[344px] h-[239px] object-cover rounded-[8px]"
                  />
                ) : (
                  <>
                    <MdOutlineCloudUpload className="w-[48px] h-[48px]" />
                    <p className="text-xl text-[#1849D6]">
                      Click to upload cover photo
                    </p>
                  </>
                )}
              </div>
              <input
                type="file"
                id="coverPhoto"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {errors.coverPhoto && (
                <p className="text-red-500 text-sm">
                  {errors.coverPhoto.message}
                </p>
              )}
            </div>

            {/* Submit Button */}
            <div className="mt-[30px]">
              <Button
                style={{
                  width: "fit-content",
                }}
                text="Create User"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </AppCard>
  );
};

export default Add;
