import React, { createContext, ReactNode } from "react";

type AppContextType = {};

export const AppContext = createContext<AppContextType>({});

export const AppContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const value: AppContextType = {};
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
