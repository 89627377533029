import React, { useState } from "react";
import { VerifyOtpType } from "../../Utils/Types";
import { ValidateAuth } from "./ValidateAuth";
import { VerifyEmailHeroDetails } from "../../Utils/Utils";
import { AuthCard as Card } from "../Card";
import { BiErrorAlt } from "react-icons/bi";
import Hero from "./Hero";
import logo from "../../Assets/others/logo.png";
import Button from "../../UI/Button";
import OtpInput from "react-otp-input";
import { toast } from "sonner";
import api from "../../API/axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../../UI/Spinner";

const VerifyOtp: React.FC = React.memo(() => {
  // Input fields
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");

  // Error fields
  const [inputErrors, setInputErrors] = useState<VerifyOtpType>({
    otp: {
      valid: false,
      message: "",
      context: "",
      type: "error",
    },
    none: false,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors = ValidateAuth(otp, "verify_otp");
    setInputErrors(errors);
    if (errors.none) {
      setLoading(true);
      try {
        const response = await api.post("/auth/confirm-otp", {
          email: sessionStorage.getItem("otp-email"),
          otp,
        });
        if (response.status !== 200) {
          toast.error(response.data.message["1"]);
        } else {
          navigate("/reset", {
            state: {
              otpToken: response.data.data.token,
            },
          });
          sessionStorage.removeItem("otp-email");
        }
      } catch (error: any) {
        console.error(error);
        toast.error(error.response.data.message["1"]);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Card>
      {loading && <Spinner />}
      <div className="w-full relative overflow-y-scroll my-6 px-6 hide-scrollbar">
        <img className="w-[160px] mx-auto" src={logo} alt="Logo" />
        <div className="w-full max-w-[500px] mx-auto relative top-[45%] translate-y-[-45%]">
          <div className="bg-green w-auto flex flex-row items-center mb-4 gap-3 p-2 rounded-md text-authGrey">
            <BiErrorAlt size={35} color="rgba(59, 185, 0, 1)" />
            Verification code sent to your email
          </div>

          <h1 className="text-4xl font-bold mb-2">Forgot Password</h1>
          <p className="text-authGrey">Please enter the code to continue.</p>

          <form
            className=" w-full flex flex-col gap-4 mt-2"
            onSubmit={handleSubmit}
          >
            <small className="text-error">{inputErrors.otp.message}</small>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              containerStyle={{
                padding: "0",
                gap: "1rem",
                marginBottom: "1.5rem",
              }}
              inputStyle={{
                width: "100%",
                padding: "1rem 0.7rem",
                border: "0.1rem solid rgba(60, 233, 255, 0.65)",
                textAlign: "center",
                borderRadius: "0.7rem",
              }}
              // renderSeparator={<span>&nbsp;</span>}
              renderInput={(props) => <input {...props} />}
              skipDefaultStyles
            />
            <Button
              style={{}}
              text="Verify"
              eventHandler={() => {}}
              type="submit"
            />
          </form>
        </div>
      </div>
      <Hero
        headerText={VerifyEmailHeroDetails.headerText}
        bodyText={VerifyEmailHeroDetails.bodyText}
      />
    </Card>
  );
});

export default VerifyOtp;
